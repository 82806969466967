/*@import url('http://fonts.cdnfonts.com/css/dalek');
@import url('http://fonts.cdnfonts.com/css/dalek-pinpoint');
/*@import url('http://fonts.cdnfonts.com/css/greek-to-me');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');*/
/*@import url('//db.onlinewebfonts.com/c/50cd2aad9c8f35800bb6beac3ad42f16?family=Azonix');*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "azonix";
        src: url('fonts/Azonix.otf');
        font-display: swap;
    }
}

@layer components {
    .popHeight{
        height: calc(100vh - 70px);
    }
    .fill_mode{
        animation-fill-mode: both;
    }
    .defaut{
        
        
        display: inline-block;
        padding-bottom:4px;
        background-image: linear-gradient(#E43A19, #E43A19);
        background-position: 0 78%; /*OR bottom left*/
        background-size: 0% 3px;
        background-repeat: no-repeat;
        transition:
            background-size 0.3s,
            background-position 0s 0.3s; /*change after the size immediately*/
    }
    
    .selected {
        color : #E43A19;
        /*text-decoration-line: underline;
        text-underline-offset: 8px; 
        text-decoration-thickness: 15%;   */    
        background-position: 100% 78%; /*OR bottom right*/
        background-size: 100% 3px;
      }
}